// Styles from https://pygments.org/styles/

// Taken from 'lightbulb'
[data-theme-mode="dark"]
  div.pygments
    pre
      line-height: 125%
    td
      &.linenos
        .normal
          color: #3c4354
          background-color: transparent
          padding-left: 5px
          padding-right: 5px
        .special
          color: #3c4354
          background-color: #ffffc0
          padding-left: 5px
          padding-right: 5px
    span
      &.linenos
        color: #3c4354
        background-color: transparent
        padding-left: 5px
        padding-right: 5px
        &.special
          color: #3c4354
          background-color: #ffffc0
          padding-left: 5px
          padding-right: 5px
    .hll
      background-color: #6e7681
    .c
      color: #8796af
    .err
      color: #f88f7f
    .esc
      color: #d4d2c8
    .g
      color: #d4d2c8
    .k
      color: #FFAD66
    .l
      color: #D5FF80
    .n
      color: #d4d2c8
    .o
      color: #FFAD66
    .x
      color: #d4d2c8
    .p
      color: #d4d2c8
    .ch
      color: #f88f7f
      font-style: italic
    .cm
      color: #8796af
    .cp
      color: #FFAD66
      font-weight: bold
    .cpf
      color: #8796af
    .c1
      color: #8796af
    .cs
      color: #8796af
      font-style: italic
    .gd
      color: #f88f7f
      background-color: #3d1e20
    .ge
      color: #d4d2c8
      font-style: italic
    .ges
      color: #d4d2c8
    .gr
      color: #f88f7f
    .gh
      color: #d4d2c8
    .gi
      color: #6ad4af
      background-color: #19362c
    .go
      color: #8796af
    .gp
      color: #d4d2c8
    .gs
      color: #d4d2c8
      font-weight: bold
    .gu
      color: #d4d2c8
    .gt
      color: #f88f7f
    .kc
      color: #FFAD66
    .kd
      color: #FFAD66
    .kn
      color: #FFAD66
    .kp
      color: #FFAD66
    .kr
      color: #FFAD66
    .kt
      color: #73D0FF
    .ld
      color: #D5FF80
    .m
      color: #DFBFFF
    .s
      color: #D5FF80
    .na
      color: #FFD173
    .nb
      color: #FFD173
    .nc
      color: #73D0FF
    .no
      color: #FFD173
    .nd
      color: #8796af
      font-weight: bold
      font-style: italic
    .ni
      color: #95E6CB
    .ne
      color: #73D0FF
    .nf
      color: #FFD173
    .nl
      color: #d4d2c8
    .nn
      color: #d4d2c8
    .nx
      color: #d4d2c8
    .py
      color: #FFD173
    .nt
      color: #5CCFE6
    .nv
      color: #d4d2c8
    .ow
      color: #FFAD66
    .pm
      color: #d4d2c8
    .w
      color: #d4d2c8
    .mb
      color: #DFBFFF
    .mf
      color: #DFBFFF
    .mh
      color: #DFBFFF
    .mi
      color: #DFBFFF
    .mo
      color: #DFBFFF
    .sa
      color: #F29E74
    .sb
      color: #D5FF80
    .sc
      color: #D5FF80
    .dl
      color: #D5FF80
    .sd
      color: #8796af
    .s2
      color: #D5FF80
    .se
      color: #95E6CB
    .sh
      color: #D5FF80
    .si
      color: #95E6CB
    .sx
      color: #95E6CB
    .sr
      color: #95E6CB
    .s1
      color: #D5FF80
    .ss
      color: #DFBFFF
    .bp
      color: #5CCFE6
    .fm
      color: #FFD173
    .vc
      color: #d4d2c8
    .vg
      color: #d4d2c8
    .vi
      color: #d4d2c8
    .vm
      color: #d4d2c8
    .il
      color: #DFBFFF


// Taken from 'lovelace' (because I couldn't resist…)
[data-theme-mode="light"]
  div.pygments
    pre
      line-height: 125%
    td
      &.linenos
        .normal
          color: inherit
          background-color: transparent
          padding-left: 5px
          padding-right: 5px
        .special
          color: #000000
          background-color: #ffffc0
          padding-left: 5px
          padding-right: 5px
    span
      &.linenos
        color: inherit
        background-color: transparent
        padding-left: 5px
        padding-right: 5px
        &.special
          color: #000000
          background-color: #ffffc0
          padding-left: 5px
          padding-right: 5px
    .hll
      background-color: #ffffcc
    .c
      color: #565656
      font-style: italic
    .err
      background-color: #a848a8
    .k
      color: #2838b0
    .l
      color: #353f20
    .n
      color: #2e2d2a
    .o
      color: #666666
    .p
      color: #565656
    .ch
      color: #287088
      font-style: italic
    .cm
      color: #565656
      font-style: italic
    .cp
      color: #289870
    .cpf
      color: #565656
      font-style: italic
    .c1
      color: #565656
      font-style: italic
    .cs
      color: #565656
      font-style: italic
    .gd
      color: #c02828
    .ge
      font-style: italic
    .ges
      font-weight: bold
      font-style: italic
    .gr
      color: #c02828
    .gh
      color: #666666
    .gi
      color: #388038
    .go
      color: #555555
    .gp
      color: #333333
    .gs
      font-weight: bold
    .gu
      color: #333333
    .gt
      color: #2838b0
    .kc
      color: #333333
      font-style: italic
    .kd
      color: #2838b0
      font-style: italic
    .kn
      color: #2838b0
    .kp
      color: #2838b0
    .kr
      color: #2838b0
    .kt
      color: #2838b0
      font-style: italic
    .m
      color: #333333
    .s
      color: #b83838
    .na
      color: #388038
    .nb
      color: #388038
    .nc
      color: #287088
    .no
      color: #b85820
    .nd
      color: #287088
    .ni
      color: #709030
    .ne
      color: #908828
    .nf
      color: #785840
    .nl
      color: #1d6a4f
    .nn
      color: #185b43
    .nt
      color: #2838b0
    .nv
      color: #b04040
    .ow
      color: #a848a8
    .pm
      color: #555555
    .w
      color: #a89028
    .mb
      color: #444444
    .mf
      color: #444444
    .mh
      color: #444444
    .mi
      color: #444444
    .mo
      color: #444444
    .sa
      color: #444444
    .sb
      color: #b83838
    .sc
      color: #a848a8
    .dl
      color: #b85820
    .sd
      color: #b85820
      font-style: italic
    .s2
      color: #b83838
    .se
      color: #709030
    .sh
      color: #b83838
    .si
      color: #b83838
      text-decoration: underline
    .sx
      color: #a848a8
    .sr
      color: #a848a8
    .s1
      color: #b83838
    .ss
      color: #b83838
    .bp
      color: #388038
      font-style: italic
    .fm
      color: #b85820
    .vc
      color: #b04040
    .vg
      color: #908828
    .vi
      color: #b04040
    .vm
      color: #b85820
    .il
      color: #444444
